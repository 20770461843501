import * as React from "react"
import { Link } from "gatsby"

import { Container } from "../components/global"
import Layout from "../components/Layout"
import styled from "styled-components"

export default function NotFound() {
  return (
    <Layout>
      <HeaderWrapper>
        <Container>
          <h1>Lost in the Jungle!</h1>
          <br />
          <h3>It seems you reached a dead end of this website.</h3>
          <br />
          <br />
          <h4>What's next?</h4>
          <p>
            <Link to="/">
              Go back to the comfort of the home &nbsp;&#x2794;
            </Link>
          </p>
          <p>
            <Link to="/writings">
              In a mood to read? Look into my blog! &nbsp;&#x2794;
            </Link>
          </p>
          <p>
            <Link to="/photography">
              Want some visual treat? Look into my captures &nbsp;&#x2794;
            </Link>
          </p>
        </Container>
      </HeaderWrapper>
    </Layout>
  )
}

const HeaderWrapper = styled.header`
  background-color: var(--color-accent);
  padding: 96px 0;
  height: 100vh;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }
`
